<form (ngSubmit)="onSubmit(form)" [formGroup]="form" novalidate>
  <div class="modal-header">
    <h4 [innerHTML]="addContactMsg" class="modal-title pull-left"></h4>
    <button (click)="cancel()" aria-label="Close" class="close pull-right" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group mb-3">
      <label for="name">{{ 'contacts.name' | translate }} *</label>
      <input class="form-control" formControlName="name" id="name" placeholder="{{ 'contacts.name_placeholder' | translate }}" type="text"/>
    </div>

    <div class="form-group mb-3">
        <label>{{ 'contacts.email' | translate }} *</label>
        <app-email-input (errors)="emailErrors($event)"
                         (valueChange)="patchEmail($event)"
                         [placeholder]="'contacts.email_placeholder' | translate"
                         [value]="emailControl?.value"></app-email-input>
    </div>

  <div class="form-group mb-3">
      <label>{{ 'contacts.phone' | translate }}</label>
      <app-phone-number-input (errors)="phoneErrors($event)"
                              (update)="updatePhone($event)"
                              [placeHolder]="'contacts.phone_placeholder' | translate"
                              [value]="phoneControl?.value"
      ></app-phone-number-input>
  </div>

    <div class="form-group mb-3">
      <label for="department">{{ 'contacts.department' | translate }}</label>
      <input class="form-control" formControlName="department" id="department" placeholder="{{ 'contacts.department_placeholder' | translate }}" type="text"/>
    </div>

    <app-form-input-select [field]="languageField" [form]="form" [isRequired]="true"></app-form-input-select>

    <div class="form-group mb-4">
      <label class="mb-3">{{ 'contacts.default_authentication' | translate }}</label>
      <app-auth-methods (toggle)="toggleAuthMethod($event)"
                        [disabled]="disabledAuthMethods"
                        [enabled]="availableMethods"
                        [signatory]="contact">
      </app-auth-methods>
    </div>

    <div class="form-group mb-3">
      <div class="checkbox c-checkbox mt-2">
        <label for="privacyProtected">
          <input formControlName="privacyProtected" id="privacyProtected" type="checkbox" value="true">
          <span class="fa fa-check"></span>
            {{ 'contacts.privacy_protected' | translate }}
        </label>
      </div>
    </div>


    <div *ngIf="mode === 2" class="form-group mb-3">
      <div class="checkbox c-checkbox mt-2">
        <label for="saveToList">
          <input formControlName="saveToList" id="saveToList" type="checkbox" value="true">
          <span class="fa fa-check"></span>
            {{ 'contacts.save_to_list' | translate }}
        </label>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <div *ngIf="!submitting" class="buttons">
        <button (click)="cancel()" class="btn btn-outline-danger mr-3" type="button">{{ 'contacts.closeModalBtn' | translate }}</button>
        <button [disabled]="form.invalid" class="btn  btn-secondary" type="submit">{{ 'contacts.save' | translate }}</button>
    </div>
    <div *ngIf="submitting">
      <div class="ball-clip-rotate"><div></div></div>
    </div>
  </div>
</form>
