import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { NgxHateoasClientConfigurationService } from '@lagoshny/ngx-hateoas-client';
import { environment } from '../../../environments/environment';
import {ErrorDisplayService} from '@services/error-display/error-display.service';
import {TranslateService} from '@ngx-translate/core';

export interface EnvConfig {
    name?: string;
    accountUUID?: string;
    production: boolean;
    debug: boolean;
    dtmConfigUrl: string;
    apiBaseUrl: string;
    baseUrl: string;
    springAppUrl: string;
    hostUrl: string;
    keycloakConfig: {
        url: string;
        realm: string;
        clientId: string;
        clientUuid: string;
    };
    helpheroAppId: string;
    manageUsers: boolean;
    uploadFileSizeLimit: number | null;
}

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    constructor(
        private readonly http: HttpClient,
        private readonly hateoasConfig: NgxHateoasClientConfigurationService,
        private readonly errorDisplayService: ErrorDisplayService,
        private readonly translate: TranslateService
    ) {
        if (!environment.production) {
            this._config = environment;
        }
    }

    private _config!: EnvConfig;

    get config(): EnvConfig {
        return this._config;
    }

    get keycloakConfig() {
        return this.config.keycloakConfig;
    }

    fetchConfig(account: string): Observable<EnvConfig> {
        // Return config stored in service
        if (this.config) {
            this.hateoasConfig.configure({http: {rootUrl: this.config.springAppUrl}});
            return of(this.config);
        }

        return this.http.get<EnvConfig>( `${environment.dtmConfigUrl}/${account}`)
            .pipe(
                first(),
                tap(res => {
                    try {
                        this._config = {...environment, ...res};
                        this.hateoasConfig.configure({http: {rootUrl: res.springAppUrl}});
                    } catch (error) {
                        this.errorDisplayService.displayError(this.translate.instant('server.server_unavailable.title'), error as string);
                    }
                })
            );
    }

    fetchConfigs(): Observable<EnvConfig[]> {
        return this.http.get<EnvConfig[]>( `${environment.dtmConfigUrl}/`);
    }

    createConfig(config: EnvConfig) {
        return this.http.post<EnvConfig>(`${environment.dtmConfigUrl}/`, config);
    }
}
