import { EventEmitter, Injectable } from '@angular/core';
import { SettingsService } from '@services/settings/settings.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { SignType, Workflow } from '@shared/models/workflow';
import { SignDocumentRequest } from '@shared/models/signrequest/sign.document.request';
import { Role, SignSignatory } from '@shared/models/signrequest/sign.signatory';
import { FileAttachmentRequest, FileItemRequest } from '@shared/models/file-attachment';
import { KeycloakService } from 'keycloak-angular';
import { SrStep } from '@shared/components/signrequest/sr-steps-header/sr-steps-header.component';
import { DocumentTemplate } from '@shared/models/documenttemplate/document.template';
import {Settings} from '@shared/models/settings/settings';
import {SignatureLabel} from '@shared/models/signrequest/signature.label';
import {validatePhoneNumber} from '@utils/phone-number.validator';
import {getAuthType} from '@shared/models/signrequest/authentication/base-authentication-method';
import {FileItem} from 'ng2-file-upload';

const STORAGE_KEY = 'resumeWorkflow';

@Injectable({
    providedIn: 'root'
})
export class SignDocumentRequestService {
    readonly navigateEvent: EventEmitter<number> = new EventEmitter<number>();
    private _step = 0;

    constructor(
        private readonly settings: SettingsService,
        private readonly keycloackService: KeycloakService
    ) {
        let resumeRequest: { step: number, workflow: Workflow, signrequest: SignDocumentRequest, resume: boolean } | null = null;
        const stored = localStorage.getItem(STORAGE_KEY);
        if (stored) {
            resumeRequest = JSON.parse(stored);
        }
        this.setResumeRequest(resumeRequest);
    }

    private _resumeWorkflow!: boolean;

    get resumeWorkflow() {
        return this._resumeWorkflow;
    }

    set resumeWorkflow(value) {
        this._resumeWorkflow = value;
    }

    private _signDocumentRequest!: SignDocumentRequest;

    get signDocumentRequest(): SignDocumentRequest {
        return this._signDocumentRequest;
    }

    set signDocumentRequest(value: SignDocumentRequest) {
        this._signDocumentRequest = value;
        this.workflow.signDocumentRequest = value;
        this.store();
    }

    private _validRequest: Subject<boolean> = new BehaviorSubject<boolean>(false);

    get validRequest(): Subject<boolean> {
        return this._validRequest;
    }

    private _invalidRecipient = false;

    get invalidRecipient(): boolean {
        return this._invalidRecipient;
    }

    set invalidRecipient(value: boolean) {
        this._invalidRecipient = value;
    }

    private _workflow!: Workflow;

    get workflow(): Workflow {
        return this._workflow;
    }

    set workflow(value: Workflow) {
        this._workflow = value;
        if (value?.signDocumentRequest) {
            this.signDocumentRequest = value.signDocumentRequest;
            this.setRequestSettings(value, this.settings.settings);
        }

        this.invalidRecipient = false;
        this.store();
    }

    private _template!: string | null;

    get template(): string | null {
        return this._template;
    }

    set template(value: string | null) {
        this._template = value;
        if (value) {
            const template = new DocumentTemplate();
            template.documentTemplateUUID = value;
            this.signDocumentRequest.documentTemplate = template;
        } else {
            this.signDocumentRequest.documentTemplate = null;
        }
        this.store();
    }

    private _attachments: FileAttachmentRequest[] = [];

    get attachments(): FileAttachmentRequest[] {
        return this._attachments;
    }

    set attachments(files: FileAttachmentRequest[]) {
        this._attachments = files;
        this.validate();
    }

    private _fileList: Map<string, FileItemRequest> = new Map();

    get fileList(): Map<string, FileItemRequest> {
        console.log('getFileList', this._fileList);
        return this._fileList;
    }

    set fileList(value: Map<string, FileItemRequest>) {
        this._fileList = value;
    }

    private _steps: SrStep[] = [
        {path: 'choice', label: 'signrequest.choose_sigining_process', hide: true},
        {path: 'workflows', label: 'signrequest.workflow'},
        {path: 'signatories', label: 'signrequest.addressees'},
        {path: 'file-upload', label: 'signrequest.document'},
        {path: 'validate', label: 'signrequest.check'},
        {path: 'send', label: 'signrequest.to_send'},
        {path: 'submitted', label: 'signrequest.step_send', hide: true},
    ];

    get steps(): SrStep[] {
        return this._steps.filter(step => {
            if (this.settings.settings.signrequestCheck) {
                return step;
            } else {
                return step.path !== 'send';
            }
        });
    }

    get description(): string {
        return this.signDocumentRequest.documentInfo.description ?? '';
    }

    set description(message: string) {
        this.signDocumentRequest.documentInfo.description = message;
        this.store();
    }

    get reference(): string {
        return this.signDocumentRequest.reference;
    }

    set reference(reference: string) {
        this.signDocumentRequest.reference = reference;
        this.store();
    }

    get separateAuditlog(): boolean | null {
        return this.signDocumentRequest.separateAuditlog;
    }

    set separateAuditlog(value: boolean | null) {
        this.signDocumentRequest.separateAuditlog = value;
        this.store();
    }

    get sendDate(): string {
        return this.signDocumentRequest?.sendDate;
    }

    set sendDate(sendDate: string) {
        this.signDocumentRequest.sendDate = sendDate;
        this.store();
    }

    get signatories(): SignSignatory[] {
        return this.signDocumentRequest?.signatories ?? [];
    }

    set signatories(signatories: SignSignatory[]) {
        this.signDocumentRequest.signatories = signatories;
        this.store();
    }

    get submitter(): { name: string, email: string } {
        return {name: this.signDocumentRequest?.submitterName, email: this.signDocumentRequest?.submitter};
    }

    get currentStep(): number {
        return this._step;
    }

    set currentStep(step: number) {
        this._step = step;
        this.store();
    }

    setStepByPath(path: 'choice' | 'workflows' | 'signatories' | 'file-upload' | 'validate' | 'send' | 'submitted') {
        const index = this.steps.findIndex(step => step.path === path);

        if (index >= 0) {
            console.log('index', index);
            this.currentStep = index;
        }
    }

    get activeStep(): Observable<SrStep> {
        return of(this.steps[this.currentStep]);
    }

    getStepByDirection(direction: string): SrStep {
        const stepIndex = direction === 'prev' ? this.currentStep - 1 : this.currentStep + 1;
        let step = this.steps[stepIndex];

        // Skip signatories if the workflow is fixed
        if (this.workflow.fixed && step.path === 'signatories') {
            step = this.steps[stepIndex > this.currentStep ? stepIndex + 1 : stepIndex - 1];
        }

        if (!this.workflow.fixed && step.path === 'workflows') {
            step = this.steps[stepIndex > this.currentStep ? stepIndex + 1 : stepIndex - 1];
        }

        return step;
    }

    setResumeRequest(resumeRequest: any): SrStep {
        this.resumeWorkflow = resumeRequest?.resume ?? false;

        if (resumeRequest && resumeRequest?.workflow && resumeRequest?.signrequest) {
            this.workflow = resumeRequest.workflow;
            this.signDocumentRequest = resumeRequest.signrequest;
            this.template = this.signDocumentRequest.documentTemplate?.documentTemplateUUID ?? null;
            this.currentStep = resumeRequest.step;
            this.navigateEvent.emit(this.currentStep);
        } else {
            this.initWorkflow();
            this.initSignRequest();
            this.currentStep = 0;
        }

        return this.steps[this.currentStep];
    }

    removeFile(fileName: string) {
        this.fileList.delete(fileName);
    }

    getSignatoriesByRole(roles: Role[]): SignSignatory[] {
        return this.signatories.filter(signatory => roles.includes(signatory.signatoryRole));
    }

    setSubmitter(name: string, email: string) {
        this.signDocumentRequest.submitterName = name;
        this.signDocumentRequest.submitter = email;
        this.store();
    }

    resetStore(): void {
        localStorage.removeItem(STORAGE_KEY);
        this.initWorkflow();
        this.initSignRequest();
        this.template = null;
        this.fileList = new Map<string, FileItemRequest>();
    }

    validate(): void {
        let valid: boolean;

        const invalidSignatory = this.getSignatoriesByRole([Role.SIGN]).some(signatory => this.validateRecipient(signatory));
        const validRecipients = !invalidSignatory && !!this.getSignatoriesByRole([Role.SIGN]).length;

        switch (this.currentStep) {
            case 1: {
                /**
                 * step 0 workflows
                 * select fixed workflow or open sign proces
                 * fixed workflow with recipients is allways true
                 * selected open workflow is also true because next step is singatories
                 */
                if (this.workflow.fixed) {
                    valid = validRecipients;
                } else {
                    valid = true;
                }
                break;
            }
            case 2: {
                /**
                 * step 1 signatories
                 * requires minimal one signatory with Role.SIGN
                 */
                valid = validRecipients;
                break;
            }
            case 3: {
                /**
                 * step 2 file upload / message
                 * minimal one file must be added
                 */
                valid = (!!this.attachments.length && validRecipients);
                break;
            }
            case 4:
            case 5: {
                /**
                 * step 3 validate
                 * step 4 ready
                 * no specific validation needed other than minimal one attachment and one recipient with Role.SIGN
                 */
                valid = (!!this.attachments.length && validRecipients);
                break;
            }
            default: {
                valid = false;
                break;
            }
        }
        this._validRequest.next(valid);
    }

    getJSON(): string {
        return JSON.stringify({
            signrequest: this.signDocumentRequest,
            workflow: this.workflow,
            step: this.currentStep,
            submitter: this.submitter,
            resume: this.resumeWorkflow
        });
    }

    private initWorkflow(): void {
        this.resumeWorkflow = false;
        this.workflow = new Workflow();
        this.workflow.id = null;
        this.workflow.name = 'Free workflow';
        this.workflow.fixed = false;
        this.workflow.signType = SignType.DOCUMENTSIGN;
    }

    private initSignRequest(): void {
        this.signDocumentRequest = new SignDocumentRequest();
        this.signDocumentRequest.setRequestSettings(this.settings.settings);

        this.keycloackService.loadUserProfile().then(user => {
            this.setSubmitter(`${user.firstName} ${user.lastName}`, (user.email ?? ''));
        });
    }

    private store(): void {
        this.validate();
        // Store request excluding attachments
        localStorage.setItem(STORAGE_KEY, this.getJSON());
    }

    private validateRecipient(recipient: SignSignatory): boolean {
        let authMethodError = false;
        const requiredAmRoles = [Role.SIGN, Role.DELIVERY];

        if (!recipient.email || !recipient.name) {
            authMethodError = true;
        }

        if (!authMethodError && recipient.email) {
            authMethodError = this.getSignatoriesByRole([Role.SIGN]).filter(signatory => signatory.email === recipient.email).length > 1;
        }

        if (!authMethodError && requiredAmRoles.includes(recipient.signatoryRole)) {
            const authmethods = recipient.authenticationMethods;

            const afterviewAuthmethods = authmethods.filter(am => getAuthType(am) !== 'PREVIEW');
            if (!afterviewAuthmethods.length) {
                authMethodError = true;
            } else {
                authMethodError = false;
                /**
                 * Validate phonenumber and patch SMS Authmethod phonenumber
                 */
                authmethods
                    .filter(am => ['smstan', 'smstan_preview'].includes(am.type))
                    .forEach((authMethod: any) => authMethodError = authMethodError
                        || !(validatePhoneNumber(true)(authMethod.mobilePhone)));

                if (authmethods.some(am => 'documenttemplate' === am.type)) {
                    authMethodError = authMethodError || !(this.template && this.signDocumentRequest.documentTemplate);
                }
            }
        }

        return authMethodError;
    }

    setRequestSettings(workflow: Workflow, settings: Settings) {
        if (settings.certificateLabel) {
            workflow.signDocumentRequest.signatureLabel = new SignatureLabel();
            workflow.signDocumentRequest.signatureLabel.xcoordinate = settings.certificateLabel.xcoordinate;
            workflow.signDocumentRequest.signatureLabel.ycoordinate = settings.certificateLabel.ycoordinate;
            workflow.signDocumentRequest.signatureLabel.width = settings.certificateLabel.width;
            workflow.signDocumentRequest.signatureLabel.height = settings.certificateLabel.height;
            workflow.signDocumentRequest.signatureLabel.pageIndex = settings.certificateLabel.pageIndex;
            if (settings.stampImage) {
                workflow.signDocumentRequest.signatureLabel.stampImage = settings.stampImage.split(',')[1];
            }
        }

        if (settings.expireAfter) {
            workflow.signDocumentRequest.expireAfter = settings.expireAfter;
        }
    }
}
